/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  grotesk, solaire,
} from '../../../styles/theme';

export const ModalAddedProductWrapper = styled.div`
padding: 60px 40px 50px;
.modal-content {
  position: relative;
}

.btn-close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: transparent;

  img {
    width: 31px;
  }
}

h2 {
  font-size: 24px;
  font-family: ${solaire};
}

p {
  font-family: ${grotesk};
  font-size: 16px;
  line-height: 21.19px;
  font-weight: 400;
}

strong {
  font-weight: 500;
}

.btn.btn-green {
  border-color: transparent;
}

@media screen and (max-width: 768px) {
  padding: 50px 30px 40px;
}
`;
