import { useRouter } from 'next/router';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../containers/AuthContainer';
import { useProduct } from '../containers/ProductContainer';
import { useStripe } from '../containers/StripeContainer';
import { ModalAddedProductWrapper } from '../pagestyles/components/ModalAddedProductWrapper';

const ModalAddedProduct: React.FC = () => {
  const router = useRouter();
  const enableAfterPay = useStripe((container) => container.enableAfterPay);
  const showModalAdded = useProduct((container) => container.showModalAdded);
  const setShowModalAdded = useProduct((container) => container.setShowModalAdded);
  const productAdded = useProduct((container) => container.productAdded);
  const subscription = useAuth((container) => container.subscription);
  const renderPaymentDate = (estimatedNextPayment: string) => {
    const listMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const firstDateTime = new Date(estimatedNextPayment);
    const month = listMonth[firstDateTime.getMonth()];
    const paymentDate = `${month} ${firstDateTime.getDate()}, ${firstDateTime.getFullYear()}`;
    return paymentDate;
  };

  const viewMyPack = () => {
    setShowModalAdded(false);
    router.push({ pathname: '/myaccount/next-delivery', query: { openpopup: true } });
  };

  return (enableAfterPay && showModalAdded && subscription) ? (
    <Modal show={showModalAdded} onHide={() => setShowModalAdded(false)} centered dialogClassName="">
      <ModalAddedProductWrapper>
        <button type="button" className="btn-close-modal" onClick={() => setShowModalAdded(false)}>
          <img src="/assets/images/closeIcon.svg" alt="" />
        </button>
        <h2 className="mb-4">{productAdded} is now added to your next pack!</h2>
        <p className="mb-5">Your new total for your next pack will be updated and charged at your next payment.&nbsp; <br /> <strong>Next Payment Date:</strong> {renderPaymentDate(subscription.nextInvoice)}</p>
        <button type="button" className="btn btn-green" onClick={viewMyPack}>View My Pack</button>
      </ModalAddedProductWrapper>
    </Modal>
  ) : null;
};
export default ModalAddedProduct;
